import {
    FlowObjectType,
    FlowObjectDefinition,
} from '../../../models/flow-object.model';
import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ElementRef,
    ViewChild,
    HostListener,
    TemplateRef
} from '@angular/core';
import { Enums } from '../../../shared/enums';
import { ToastrService } from 'ngx-toastr';
import { FlowDefinition } from '../../../models/flow.model';
import { Utils } from '../../../shared/utils';
import { OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FlowObjectDetailsDocumentComponent } from './flow-object-details-document/flow-object-details-document.component';
import { FlowObjectDetailsForwardComponent } from './flow-object-details-forward/flow-object-details-forward.component';
import { FlowObjectDetailsRegisterProcessComponent } from './flow-object-details-register-process/flow-object-details-register-process.component';
import { FLOW_OBJECT_DETAILS_START_INBOUND_API_TINYMCE_OPTIONS, FLOW_OBJECT_DETAILS_TINYMCE_OPTIONS } from './flow-object-details-tinymce-options';
import { FlowObjectDetailsDispatchProcessComponent } from './flow-object-details-dispatch-process/flow-object-details-dispatch-process.component';
import { ConfigSchema } from '../../../models/config-schema.model';

@Component({
    selector: 'flow-object-details',
    templateUrl: './flow-object-details.component.html',
    styleUrls: ['./flow-object-details.component.scss']
})
export class FlowObjectDetailsComponent implements OnInit, OnChanges {
    // #region [ViewChild]
    @ViewChild('configSchemaRef') configSchemaRef: TemplateRef<HTMLElement>;
    @ViewChild('configSchemaEditorRef') configSchemaEditorRef: ElementRef;
    @ViewChild('flowObjectDetailsDocumentRef') flowObjectDetailsDocumentRef: FlowObjectDetailsDocumentComponent;
    @ViewChild('flowObjectDetailsForwardRef') flowObjectDetailsForwardRef: FlowObjectDetailsForwardComponent;
    @ViewChild('flowObjectDetailsRegisterProcessRef') flowObjectDetailsRegisterProcessRef: FlowObjectDetailsRegisterProcessComponent;
    @ViewChild('flowObjectDetailsDispatchProcessRef') flowObjectDetailsDispatchProcessRef: FlowObjectDetailsDispatchProcessComponent;
    // #endregion

    // #region [listeners]
    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        if (event.key.toLowerCase() == 'enter') {
            event.preventDefault();
            event.stopPropagation();
        }
    }
    // #endregion

    // #region [Type properties]
    FlowObjectType: typeof FlowObjectType = FlowObjectType;
    Utils: typeof Utils = Utils;
    // #endregion

    // #region [properties]
    model: FlowObjectDefinition;
    configSchema: ConfigSchema = null;
    configSchemaFlat: string = null;
    publicMessageHtml: string = null;
    recipientMode: Enums.RecipientMode = Enums.RecipientMode.Fixed;
    tinyMceOptions: any;
    // #endregion

    // #region [getters]
    get shouldShowPublicMessageHtml(): boolean {
        return [
            FlowObjectType.StartInboundApi,
            FlowObjectType.Finish,
            FlowObjectType.FinishContent,
            FlowObjectType.FinishOutboundApi,
            FlowObjectType.GatewayApprove,
            FlowObjectType.TaskAcknowledge,
            FlowObjectType.TaskRegisterProcess,
            FlowObjectType.TaskDispatchProcess
        ].includes(this.model?.typeId);
    }
    get shouldShowFlowObjectDetailsDocument(): boolean {
        return [
            FlowObjectType.StartForm,
            FlowObjectType.GatewayApprove,
            FlowObjectType.TaskAcknowledge
        ].includes(this.model?.typeId);
    }
    get isRadioSelectionValid(): boolean {
        return (
            (
                this.model.typeId == FlowObjectType.TaskForward
                && (
                    (this.recipientMode == Enums.RecipientMode.ImmediateSupervisor && this.configSchema?.taskForward?.toImmediateSupervisor)
                    || (this.recipientMode == Enums.RecipientMode.Fixed && !Utils.isNullOrEmpty(this.configSchema?.taskForward?.recipient.id))
                    || (this.recipientMode == Enums.RecipientMode.Conditional && this.configSchema?.taskForward?.conditionalRecipients.length > 0)
                )
            ) || (
                this.model.typeId == FlowObjectType.TaskDispatchProcess
                && (
                    (this.recipientMode == Enums.RecipientMode.PublicAgent && this.configSchema?.taskDispatchProcess?.toPublicAgent)
                    || (this.recipientMode == Enums.RecipientMode.Fixed && !Utils.isNullOrEmpty(this.configSchema?.taskDispatchProcess?.dispatchRecipient.id))
                    || (this.recipientMode == Enums.RecipientMode.Conditional && this.configSchema?.taskDispatchProcess?.conditionalRecipients.length > 0)
                )
            )
        );
    }
    // #endregion

    // #region [Input/Output]
    @Input() inputModel: FlowObjectDefinition;
    @Output() inputModelChange = new EventEmitter<FlowObjectDefinition>();
    @Input() inputFlowDefinition: FlowDefinition;
    @Input() inputIsReadOnlyMode: boolean;
    @Output() outputUpdateFlowObjectEvent = new EventEmitter<FlowObjectDefinition>();
    @Output() outputCloseEvent = new EventEmitter<any>();
    // #endregion

    constructor(
        private dialog: MatDialog,
        private toastr: ToastrService
    ) { }

    // ======================
    // lifecycle methods
    // ======================

    async ngOnInit() {
        this.model = this.inputModel;
        this.publicMessageHtml = this.model.publicMessageHtml;

        if (this.model.typeId == FlowObjectType.StartInboundApi) {
            this.tinyMceOptions = FLOW_OBJECT_DETAILS_START_INBOUND_API_TINYMCE_OPTIONS;
        } else {
            this.tinyMceOptions = FLOW_OBJECT_DETAILS_TINYMCE_OPTIONS;
        }

        if (this.model.configSchema != null) {
            this.configSchema = new ConfigSchema();
            Object.assign(this.configSchema, JSON.parse(this.model.configSchema));
        }
    }

    ngOnChanges() {
        this.inputModelChange.emit(this.model);
    }

    // ======================
    // public methods
    // ======================

    updateRecipientMode(event) {
        this.recipientMode = event;
    }

    showConfigSchema() {
        if (
            this.model.typeId == FlowObjectType.TaskRegisterProcess
            && !this.flowObjectDetailsRegisterProcessRef.resolveProcessInfo()
        ) return;

        this.model.publicMessageHtml = this.publicMessageHtml;
        let tempObject = {
            model: JSON.parse(JSON.stringify(this.model)) as FlowObjectDefinition,
            configSchema: JSON.parse(JSON.stringify(this.configSchema))
        };
        delete tempObject.model.next;

        this.configSchemaFlat = btoa(encodeURIComponent(JSON.stringify(tempObject)));

        let dialog = this.dialog.open(this.configSchemaRef, {
            minWidth: '800px'
        });

        dialog.afterOpened().subscribe(() => {
            setTimeout(() => {
                this.configSchemaEditorRef.nativeElement.scrollLeft = 0;
                this.configSchemaEditorRef.nativeElement.scrollTop = 0;
            }, 1);
            setTimeout(() => {
                this.configSchemaEditorRef.nativeElement.style.opacity = '1';
            }, 100);
        });

        dialog.afterClosed().subscribe(async () => {
            if (this.inputIsReadOnlyMode) return;

            try {
                let modalObject = JSON.parse(decodeURIComponent(atob(this.configSchemaEditorRef.nativeElement.value)));
                delete modalObject.model.configSchema;

                // checa se o tipo da origem do conteúdo é o mesmo tipo do conteúdo atual e se a origem não é um FlowDefinition
                if (modalObject.model.typeId != this.model.typeId || modalObject.model.targetId != null) {
                    this.toastr.error(Enums.Messages.InvalidConfigSchemaSourceType, Enums.Messages.Error, Utils.getToastrErrorOptions());
                    return;
                }

                // checa se o código-fonte foi alterado
                if (JSON.stringify(tempObject) != JSON.stringify(modalObject)) {
                    this.configSchema = JSON.parse(JSON.stringify(modalObject.configSchema)) as ConfigSchema;
                    this.model.name = modalObject.model.name;
                    this.model.publicName = modalObject.model.publicName;
                    this.model.publicMessageHtml = modalObject.model.publicMessageHtml;
                    this.publicMessageHtml = this.model.publicMessageHtml;
                }
            } catch (error) {
                this.toastr.error(Enums.Messages.InvalidFlowObjectConfigSchemaSource, Enums.Messages.Error, Utils.getToastrErrorOptions());
                return;
            }

            switch (this.model.typeId) {
                case FlowObjectType.TaskForward:
                    this.flowObjectDetailsForwardRef.updateSourceCode(this.configSchema);
                    break;

                case FlowObjectType.TaskRegisterProcess:
                    this.flowObjectDetailsRegisterProcessRef.updateSourceCode(this.configSchema);
                    break;

                case FlowObjectType.TaskDispatchProcess:
                    this.flowObjectDetailsDispatchProcessRef.updateSourceCode(this.configSchema);
                    break;

                case FlowObjectType.StartForm:
                case FlowObjectType.GatewayApprove:
                case FlowObjectType.TaskAcknowledge:
                    this.flowObjectDetailsDocumentRef.updateSourceCode(this.configSchema);
                    break;

                default:
                    break;
            }
        });
    }

    onSubmit() {
        if (
            this.inputIsReadOnlyMode
            || (
                this.model.typeId == FlowObjectType.TaskForward
                && !this.isRadioSelectionValid
            )
        ) return false;

        if (
            (
                this.model.typeId == FlowObjectType.TaskRegisterProcess
                && !this.flowObjectDetailsRegisterProcessRef.resolveProcessInfo()
            ) || (
                this.model.typeId == FlowObjectType.TaskDispatchProcess
                && !this.flowObjectDetailsDispatchProcessRef.resolveDispatchInfo()
            )
        ) return false;

        this.model.configSchema = JSON.stringify(this.configSchema);
        this.model.publicMessageHtml = this.publicMessageHtml;

        this.outputUpdateFlowObjectEvent.emit(this.model);
    }

    closeForm() {
        this.outputCloseEvent.emit();
    }

    // ======================
    // private methods
    // ======================
}
